import React from "react"
import { PageProps } from "gatsby"
import { styled } from "../../stitches.config"
import { PageLayout } from "../layouts"
import { Paragraph, Meta, ShareButtons } from "../components"
import { SEO } from "../components/Seo/Seo"


const About: React.FC<PageProps> = () => (
  <PageLayout>
    <Container>
      <Meta title="About" />
      <Paragraph>
        I’m Matheus Toazza Tura, a Backend developer since 2016 as a professional developer,
        worked on several sectors such as {NelogicaLink}, prop trading firm, {ElysiosLink} and {AxurLink}.
        <br />Also worked on different sized companies from startups (6- people) to mid sized companies (100+ people),
        but my journey as a programmer started way before, back on 2007 at school on the {RoboticTeamLink} and later on between 2013 and 2016 programming as research intern on the university computer graphics group.
        <br /><br />
        I have a bachelor degree in Computer Engineering at {UfrgsLink}, which is ranked among the top 10 universities in Latin America,
        as an engineer, math is one of my passions, I attended twice as the calculus tutor for the university back on 2012 and 2013.
        <br /><br />
        I am specialized in Amazon Web Services as an associate level AWS Certified Developer, also I have experience with Docker, Python and Java microservices and I use Linux as main OS for over 4 years now.
        <br /><br />
        Adept of Uncle Bob clean code school and TDD as best practices and most of my personal cloud structure is built on AWS and using Infrastructure as code (cloudformation).
        <br /><br />

        {/* What I value?
          - Data driven
          - Brutal honesty, so problems can be found and fixed sooner
          - Make a section about coffee */}
      </Paragraph>
    </Container>
    <ShareButtons />
  </PageLayout>
)

const Container = styled("section", {
  length: 0,
  maxWidth: 640,
})

const UfrgsLink = <a href="https://en.wikipedia.org/wiki/Federal_University_of_Rio_Grande_do_Sul"><u style="color:#CCCCCC">Federal University of Rio Grande do Sul</u></a>
const RoboticTeamLink = <a href="https://www-opolvo-com-br.translate.goog/noticias/59021/Um-rob%C3%B4-pode-fazer-toda-a-diferen%C3%A7a.html?_x_tr_sch=http&_x_tr_sl=pt&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp"><u style="color:#CCCCCC">robotics team</u></a>
const NelogicaLink = <a href="https://www.nelogica.com.br/"><u style="color:#CCCCCC">desktop trading platform</u></a>
const AxurLink = <a href="https://www.axur.com/"><u style="color:#CCCCCC">cybersecurity</u></a>
const ElysiosLink = <a href="https://elysios-com-br.translate.goog/?_x_tr_sl=pt&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp"><u style="color:#CCCCCC">agricultural IoT</u></a>

export default About

export const Head = () => (
  <SEO title="about" pathname="/about"/>
)
